/* eslint-disable @typescript-eslint/no-unused-vars*/
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import {
  IndeedResponse as IndeedCommonModelResponse,
  LinkedinCreditsResponse,
  SendIndeedMessageParams,
  SendLinkedinMessageParams,
  SendLinkedinProMessageParams,
  UseBrowserExtension,
  BrowserExtensionInfo,
  BrowserExtensionInfoStatus,
} from './types';
import { IndeedClient } from './indeed';
import { useGTM } from 'hooks/gtm';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { isObject } from 'helpers/helpers';

const testUrls = {
  linkedin: 'https://www.linkedin.com/voyager/api/identity/profiles/me/profileView',
  indeedToken: 'https://nl.indeed.com/notifications',
  linkedinPro: 'https://www.linkedin.com/talent/api/talentRecruiterTags?count=100',
};

export const creditUrls = {
  linkedin:
    'https://www.linkedin.com/voyager/api/graphql?variables=()&queryId=voyagerRelationshipsDashCustomInviteComposeView.853d72a59b13b61229e9a90d2a42317b',
  linkedinPro:
    'https://www.linkedin.com/talent/api/talentMessageComposeInfo?decoration=%28inMailCreditInfo%28creditBalance%2CnextRefreshDate%29%29',
  indeed: 'https://resumes.indeed.com/api/common-model',
};

const cacheTime = 60 * 1000;

let browserExtensionHasInstance = false;
let statusSent = false;
let indeedBodyTokenRetry = 0;

export const useBrowserExtension = (): UseBrowserExtension => {
  const indeedClient = useRef(new IndeedClient());
  const queryClient = useQueryClient();
  const gtm = useGTM();

  useEffect(() => {
    browserExtensionHasInstance = true;
    return () => {
      browserExtensionHasInstance = false;
      indeedBodyTokenRetry = 0;
    };
  }, []);

  const { data: info = {} } = useQuery<Partial<BrowserExtensionInfo>, unknown, Partial<BrowserExtensionInfo>, string[]>(
    ['getExtensionStatus'],
    ({ queryKey: [queryKey] }) => browserExtensionClient.sendMessage(queryKey),
    { cacheTime, staleTime: cacheTime },
  );

  useEffect(() => {
    if (statusSent === false && info?.status === BrowserExtensionInfoStatus.Active) {
      statusSent = true;
      gtm.browserExtensionActivated();
    }
  }, [info?.status]);

  const { data: { status: linkedinStatus = null } = {} } = useQuery(
    ['proxyHttpRequest', { url: testUrls.linkedin, addHeadersFrom: 'linkedInClassic' }] as const,
    ({ queryKey: [queryKey, params] }) => browserExtensionClient.sendMessage(queryKey, params),
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const { data: { status: linkedinProStatus = null } = {} } = useQuery(
    [
      'proxyHttpRequest',
      {
        url: testUrls.linkedinPro,
        addHeadersFrom: 'linkedInClassic',
        headers: {
          'content-type': 'application/json; charset=UTF-8',
        },
      },
    ] as const,
    ({ queryKey: [queryKey, params] }) => browserExtensionClient.sendMessage(queryKey, params),
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const { data: { indeedCreditBalance, indeedStatus = null } = {} } = useQuery(
    ['userPlatformCredits', { url: creditUrls.indeed }] as const,
    async () => {
      const { contactsRemaining: creditBalance, subscriptionAssigned } = await getIndeedInfo();

      return { indeedCreditBalance: creditBalance, indeedStatus: subscriptionAssigned ? 200 : 401 };
    },
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const { data: { body: indeedTokenBody = null } = {} } = useQuery(
    ['proxyHttpRequest', { url: testUrls.indeedToken }] as const,
    ({ queryKey: [queryKey, params] }) => browserExtensionClient.sendMessage(queryKey, params),
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const { data: { linkedinCreditBalance } = {} } = useQuery(
    ['userPlatformCredits', { url: creditUrls.linkedinPro }] as const,
    async () => {
      const creditBalance = await getLinkedInCredits();

      return { linkedinCreditBalance: creditBalance };
    },
    { enabled: info?.status === BrowserExtensionInfoStatus.Active, cacheTime, staleTime: cacheTime },
  );

  const indeedToken: string | null = useMemo(() => {
    if (indeedTokenBody && indeedStatus === 200) {
      const regexR = indeedTokenBody.match(/indeedcsrftoken=.*?\\/);

      if (!regexR?.[0]) {
        if (indeedBodyTokenRetry++ < 3) {
          return queryClient.resetQueries(['proxyHttpRequest', { url: testUrls.indeedToken }]);
        }

        return null;
      }

      return regexR[0].replace('indeedcsrftoken=', '').replace('\\', '');
    }

    return null;
  }, [indeedTokenBody, indeedStatus]);

  useEffect(() => {
    if (indeedToken) {
      indeedClient.current.setCtk(indeedToken);
    }
  }, [indeedToken]);

  const { data: indeedProjects } = useQuery(
    ['indeedProjectsList'],
    async () => {
      const response = await indeedClient.current.getProjectList();

      return response.findTalentProjectBriefs.projects;
    },
    { enabled: !!indeedToken, cacheTime, staleTime: cacheTime, initialData: [], initialDataUpdatedAt: 0 },
  );

  const { data: indeedJobs } = useQuery(
    ['indeedJobsList'],
    async () => {
      const response = await indeedClient.current.getJobsList();

      return response.dradisJobModels;
    },
    { enabled: !!indeedToken, cacheTime, staleTime: cacheTime, initialData: [], initialDataUpdatedAt: 0 },
  );

  const hasLinkedinFreeInviteRemaining = useCallback(async () => {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: creditUrls.linkedin,
      method: 'GET',
      addHeadersFrom: 'linkedInClassic',
    });

    const data = JSON.parse(response.body);

    if (typeof data === 'object') {
      const elements: unknown[] = data.data?.relationshipsDashCustomInviteComposeViewByInviter?.elements || [];

      const runOutInvites = elements.some(
        (element) => isObject(element) && isObject(element.creditLimitReachedPremiumUpsellSlot),
      );

      if (runOutInvites) {
        return false;
      }
    }

    return true;
  }, []);

  window.hasLinkedinFreeInviteRemaining = hasLinkedinFreeInviteRemaining;

  const sendLinkedinMessage = async (data: SendLinkedinMessageParams): Promise<any> => {
    return browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/voyager/api/voyagerRelationshipsDashMemberRelationships?action=verifyQuotaAndCreate',
      method: 'POST',
      body: JSON.stringify(data),
      addHeadersFrom: 'linkedInClassic',
    });
  };

  const sendLinkedinProMessage = ({
    subject,
    body,
    signature,
    candidate_id,
  }: SendLinkedinProMessageParams): Promise<any> => {
    const data = {
      elements: [
        {
          subject,
          body,
          signature,
          postOption: {
            value: {
              'com.linkedin.talent.message.InMailPost': {
                recipient: `urn:li:ts_profile:${candidate_id}`,
              },
            },
          },
        },
      ],
    };

    return browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/talent/api/talentMessagePosts',
      method: 'POST',
      body: JSON.stringify(data),
      addHeadersFrom: 'linkedInClassic',
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        'x-restli-method': 'BATCH_CREATE',
      },
    });
  };

  const createIndeedProject = (name: string) => {
    return indeedClient.current.createProject(name);
  };

  const sendIndeedMessage = (data: SendIndeedMessageParams) => {
    return indeedClient.current.sendMessage({
      candidateId: data.accountKeys[0],
      description: data.description,
      jobTitle: data.jobTitle,
      message: data.message,
      senderName: data.senderName,
      senderCompany: data.senderCompany,
      projectKey: data.projectKey,
    });
  };

  const getLinkedInCredits = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: creditUrls.linkedinPro,
        method: 'GET',
        addHeadersFrom: 'linkedInClassic',
      });

      const {
        inMailCreditInfo: { creditBalance },
      }: LinkedinCreditsResponse = JSON.parse(response.body);

      return creditBalance;
    } catch (e) {
      throw e;
    }
  };

  const getIndeedInfo = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: creditUrls.indeed,
        method: 'GET',
      });

      const {
        subscriptionInfo: { contactsRemaining, subscriptionAssigned },
      }: IndeedCommonModelResponse = JSON.parse(response.body);

      return { contactsRemaining, subscriptionAssigned };
    } catch (e) {
      throw e;
    }
  };

  return useMemo(
    () => ({
      linkedinStatus: linkedinStatus === 200 ? 'Logged In' : linkedinStatus === null ? 'Unknown' : 'Logged Out',
      linkedinProStatus:
        linkedinProStatus === 200 ? 'Logged In' : linkedinProStatus === null ? 'Unknown' : 'Logged Out',
      indeedStatus: indeedStatus === 200 ? 'Logged In' : indeedStatus === null ? 'Unknown' : 'Logged Out',
      info,
      sendLinkedinMessage,
      sendLinkedinProMessage,
      sendIndeedMessage,
      createIndeedProject,
      linkedinCreditBalance,
      indeedCreditBalance,
      indeedProjects,
      indeedJobs,
      hasLinkedinFreeInviteRemaining,
    }),
    [
      linkedinStatus,
      linkedinProStatus,
      indeedStatus,
      linkedinCreditBalance,
      indeedCreditBalance,
      JSON.stringify(info),
      JSON.stringify(indeedProjects?.sort()),
      JSON.stringify(indeedJobs?.sort()),
      hasLinkedinFreeInviteRemaining,
    ],
  );
};
