import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CampaignCandidateSaveNotesResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { PageContext } from 'hooks/shared/usePageContext.types';
import localforage from 'localforage';
import { Candidate } from 'model';
import { useContext } from 'react';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useSaveNotes = (params: WrappedHookParams) => {
  const { currentUser: { id: userId = -1 } = {} } = useContext(UserContext);

  return useCandidateAction<SaveNotesHookCallbackParams, SaveNotesCallbackParams>(
    async ({ candidate, notes }) => {
      // WORKAROUND: this request is not necessary right now, on the first version of the new frontend
      // since there is no edit note feature, we can just save the note
      // * START *
      // const shouldSaveANewNote = await checkIfShouldCreateOrSaveANote({
      //   candidate,
      //   userId,
      //   full_name: `${first_name} ${last_name}`,
      // });
      // * END *

      const shouldSaveANewNote = true;

      const firstAvailableNote = candidate.notes.find((n) => !!n.id);

      // eslint-disable-next-line no-async-promise-executor
      const [note, isNew] = await new Promise<[CampaignCandidateSaveNotesResponse, boolean]>(async (res) => {
        if (shouldSaveANewNote || !firstAvailableNote) {
          const { data: note } = await campaignsClient.postCampaignCandidateNotes({
            es_person_id: candidate.es_person_id,
            campaign_id: candidate.campaignId,
            notes,
          });

          localStorage.setItem(
            LAST_SAVE_NOTE_ENTRY_DATE_KEY({ es_person_id: candidate.es_person_id, user_id: userId }),
            `${new Date().valueOf() + LAST_SAVE_NOTE_ENTRY_DEBOUNCE}`,
          );
          localforage.setItem(
            LAST_SAVE_NOTE_ENTRY_DATE_KEY({ es_person_id: candidate.es_person_id, user_id: userId }),
            `${new Date().valueOf() + LAST_SAVE_NOTE_ENTRY_DEBOUNCE}`,
          );

          return res([note, true]);
        }

        const { data: note } = await campaignsClient.putCampaignCandidateNotes({
          es_person_id: candidate.es_person_id,
          campaign_id: candidate.campaignId,
          note_id: firstAvailableNote.id,
          notes,
        });

        return res([note, false]);
      });

      return { notes: [note], isNew };
    },
    {
      onSuccess: ({ fcParams: { candidate }, gtm }) => {
        return async ({ candidateOrchestrator: co, pageContext, data: { notes, isNew } }) => {
          if (isNew) {
            gtm.updatedNotesTabOnCandidateCard(candidate.campaignId, candidate.es_person_id);
          }

          if (pageContext === PageContext.Campaign) {
            co.campaign.update.notes(notes);
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            co.share.update.notes(notes);
          }
        };
      },
    },
  )(params);
};

const LAST_SAVE_NOTE_ENTRY_DATE_KEY = ({ es_person_id, user_id }: { es_person_id: string; user_id?: number }) =>
  `LAST_SAVE_NOTE_ENTRY_DATE_KEY-${user_id}-${es_person_id}`;

/// In milliseconds
const LAST_SAVE_NOTE_ENTRY_DEBOUNCE = 5 * 60 * 1000;

export const checkIfShouldCreateOrSaveANote = async ({
  candidate,
  userId,
  full_name,
}: {
  candidate: Candidate;
  userId: number;
  full_name: string;
}): Promise<boolean> => {
  const lastCandidateNotes = await campaignsClient.getCampaignCandidateNotes({
    es_person_id: candidate.es_person_id,
  });
  const [lastNote] = lastCandidateNotes.data.sort((a, b) => b.id - a.id);

  const firstAvailableNote = candidate.notes.find((n) => !!n.id);
  const isLastNoteAdded = !lastNote || firstAvailableNote?.id === lastNote.id;
  const isLastNoteOwnedByMe = lastNote && lastNote.created_by.full_name === full_name;
  const lastEntry =
    localStorage.getItem(LAST_SAVE_NOTE_ENTRY_DATE_KEY({ user_id: userId, es_person_id: candidate.es_person_id })) ||
    (await localforage.getItem(
      LAST_SAVE_NOTE_ENTRY_DATE_KEY({ user_id: userId, es_person_id: candidate.es_person_id }),
    )) ||
    '0';
  const havePastFiveMinutesSinceLastEntry = new Date().valueOf() > parseInt(lastEntry);

  return Promise.resolve((isLastNoteAdded && havePastFiveMinutesSinceLastEntry) || !isLastNoteOwnedByMe);
};

type SaveNotesCallbackParams = {
  notes: CampaignCandidateSaveNotesResponse[];
  isNew: boolean;
};

type SaveNotesHookCallbackParams = {
  notes: string;
};
