import { routes, shouldShowRobinWrapped } from 'config';
import OAuthNylasFailPopup from 'pages/EmailSettingsPage/components/OAuthNylasFailPopup';
import OAuthNylasSuccessPopup from 'pages/EmailSettingsPage/components/OAuthNylasSuccessPopup';
import { SearchV3MainLayout } from 'pages/SearchV3/SearchV3MainLayout';
import { lazy } from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import { AdminLayout } from 'shared/components/layouts';
import { SharedLayoutComponents } from 'shared/components/layouts/SharedLayoutComponents/SharedLayoutComponents';
import { Dashboard } from 'views/Admin';
import { LogIn, LoginTenant, PasswordRecovery, ResetPassword } from 'views/LoggedOut';
import { RoutesSuspense } from './RoutesSuspense';
import { RequireAuth } from './components';
import { useRedirectWhenTranslateOn } from './useRedirectWhenTranslateOn';
import { SearchV3List } from 'pages/SearchV3/SearchV3List';
import { useBrowserExtensionSync } from 'hooks/shared/useBrowserExtensionSync';

const JobBoards = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.JobBoards })));
const JobBoardForm = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.JobBoardForm })));
const ATSIntegrations = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.ATSIntegrations })));
const ATSIntegrationForm = lazy(() =>
  import('views/LoggedIn').then((module) => ({ default: module.ATSIntegrationForm })),
);
const UserMe = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.UserMe })));
const UserForm = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.UserForm })));
const BrowserExtension = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.BrowserExtension })));
const Teams = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.Teams })));
const TeamForm = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.TeamForm })));
const TenantEmailSettingsForm = lazy(() =>
  import('views/LoggedIn').then((module) => ({ default: module.TenantEmailSettingsForm })),
);
const TeamDetails = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.TeamDetails })));
const Users = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.Users })));
const Notifications = lazy(() => import('views/LoggedIn').then((module) => ({ default: module.Notifications })));

/**
 * V2
 */

const SharedCandidatePage = lazy(() => import('pages').then((module) => ({ default: module.SharedCandidatePage })));
const RobinWrappedPage = lazy(() => import('pages').then((module) => ({ default: module.RobinWrappedPage })));
const GoogleTranslateNotAvailablePage = lazy(() =>
  import('pages').then((module) => ({ default: module.GoogleTranslateNotAvailablePage })),
);
const DashboardPage = lazy(() => import('pages').then((module) => ({ default: module.DashboardPage })));
const EmailSettingsPage = lazy(() => import('pages').then((module) => ({ default: module.EmailSettingsWithProvider })));

/**
 * V3
 */

const SearchV3 = lazy(() => import('pages').then((module) => ({ default: module.SearchV3 })));

const Routes = () => {
  useRedirectWhenTranslateOn();
  useBrowserExtensionSync();

  return (
    <>
      <ReactRouterDomRoutes>
        {[
          routes.login,
          /** this route doesn't actually exist but is sent in emails, we need to remove it from emails so we can remove it from here */
          '/Account/EmailConfirmation',
        ].map((path, index) => (
          <Route
            key={index}
            path={path}
            element={
              <RoutesSuspense>
                <LogIn />
              </RoutesSuspense>
            }
          />
        ))}
        <Route
          path={routes.loginTenant}
          element={
            <RoutesSuspense>
              <LoginTenant />
            </RoutesSuspense>
          }
        />
        <Route
          path={routes.recoverPassword}
          element={
            <RoutesSuspense>
              <PasswordRecovery />
            </RoutesSuspense>
          }
        />
        <Route
          path={routes.resetPassword}
          element={
            <RoutesSuspense>
              <ResetPassword />
            </RoutesSuspense>
          }
        />
        <Route
          path={routes.gtNotAvailable}
          element={
            <RoutesSuspense>
              <GoogleTranslateNotAvailablePage />
            </RoutesSuspense>
          }
        />
        <Route
          path={routes.nylasSuccess}
          element={
            <RoutesSuspense>
              <OAuthNylasSuccessPopup />
            </RoutesSuspense>
          }
        />
        <Route
          path={routes.nylasFailed}
          element={
            <RoutesSuspense>
              <OAuthNylasFailPopup />
            </RoutesSuspense>
          }
        />
        <Route
          path={routes.admin.root}
          element={
            <RoutesSuspense>
              <RequireAuth
                shouldAllow={({ auth }) => ({ isAllowed: auth.isStaff, redirectTo: routes.admin.dashboard })}>
                <AdminLayout />
              </RequireAuth>
            </RoutesSuspense>
          }>
          <Route path={routes.admin.dashboard} element={<Dashboard />} />
        </Route>
        <Route
          path="/"
          element={
            <RoutesSuspense>
              <RequireAuth>
                <SearchV3MainLayout />
              </RequireAuth>
            </RoutesSuspense>
          }>
          <Route
            path={routes.search({ campaignId: 'campaigns' })}
            element={
              <RoutesSuspense>
                <RequireAuth>
                  <SearchV3List />
                </RequireAuth>
              </RoutesSuspense>
            }
          />
          (
          {[
            routes.search(),
            routes.search({ campaignId: ':id' }),
            routes.search({ campaignId: ':id', tab: ':tab' }),
          ].map((route, index) => (
            <Route
              key={index}
              path={route}
              element={
                <RoutesSuspense>
                  <SearchV3 />
                </RoutesSuspense>
              }
            />
          ))}
          )
          <Route
            path={routes.jobBoards}
            element={
              <RoutesSuspense>
                <JobBoards />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.newJobBoard}
            element={
              <RoutesSuspense>
                <JobBoardForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.jobBoard()}
            element={
              <RoutesSuspense>
                <JobBoardForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.atsConnections}
            element={
              <RoutesSuspense>
                <ATSIntegrations />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.newAtsConnection}
            element={
              <RoutesSuspense>
                <ATSIntegrationForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.atsConnection()}
            element={
              <RoutesSuspense>
                <ATSIntegrationForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.userMe}
            element={
              <RoutesSuspense>
                <UserMe />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.users}
            element={
              <RoutesSuspense>
                <Users />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.newUser}
            element={
              <RoutesSuspense>
                <UserForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.user()}
            element={
              <RoutesSuspense>
                <UserForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.browserExtension}
            element={
              <RoutesSuspense>
                <BrowserExtension />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.notifications}
            element={
              <RoutesSuspense>
                <Notifications />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.teams}
            element={
              <RoutesSuspense>
                <Teams />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.newTeam}
            element={
              <RoutesSuspense>
                <TeamForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.tenantEmailSettings}
            element={
              <RoutesSuspense>
                <TenantEmailSettingsForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.team()}
            element={
              <RoutesSuspense>
                <TeamForm />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.detailsTeam()}
            element={
              <RoutesSuspense>
                <TeamDetails />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.sharedCandidatePage()}
            element={
              <RoutesSuspense>
                <SharedCandidatePage />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.candidatePage()}
            element={
              <RoutesSuspense>
                <SharedCandidatePage />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.emailSettings}
            element={
              <RoutesSuspense>
                <EmailSettingsPage />
              </RoutesSuspense>
            }
          />
          <Route
            path={routes.dashboard}
            element={
              <RoutesSuspense>
                <DashboardPage />
              </RoutesSuspense>
            }
          />
          {shouldShowRobinWrapped() && (
            <Route
              path={routes.robinWrapped}
              element={
                <RoutesSuspense>
                  <RobinWrappedPage />
                </RoutesSuspense>
              }
            />
          )}
        </Route>
      </ReactRouterDomRoutes>

      <SharedLayoutComponents />
    </>
  );
};

export default Routes;
