import { routes } from 'config';
import { candidatesService } from 'services';
import { candidatesUtils, sharedUtils } from 'utils';
import { default as InsightChecker } from './CandidateInsight';

import type {
  CampaignCandidateResponse,
  CampaignCandidateSaveNotesResponse,
  CampaignResponse,
  CandidateActivityLog,
} from 'clients/CampaignsClient/CampaignsClient.types';
import type { CandidateResponse, Job, License, Source, SourceMeta } from 'clients/CampaignsClient/CandidateResponse';
import { uniqueId } from 'lodash';
import { CANDIDATE_STATUS, CandidateStatus } from 'services/candidates';
import { ShowingPreferences } from 'services/candidates/getPreferences';
import { CANDIDATE_ACTIVITY_LOG_STATUS, CandidateActivityLogStatus } from 'services/candidates/types';
import type { Dictionary } from 'shared/contexts/LocalizationContext/types';
import { LatLon } from 'shared/contexts/SearchContext/FiltersContext/types';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import stripNonValidPhoneCharacters from 'utils/shared/stripNonValidPhoneCharacters';

export default class Candidate implements IMCandidate {
  private campaignIdResponse: number;
  private candidateResponse: CampaignCandidateResponse;
  private candidateJobBoardMatchesWithCandidateId: Partial<Record<CampaignJobboard, string>>;
  private candidateActivityLog: CandidateActivityLog[] = [];
  private candidateNotesResponse: CampaignCandidateSaveNotesResponse[] | undefined;
  private _mainJobboard: CampaignJobboard;
  private _mainJobboardInfo: CandidateResponse;
  private _removedJobboards: CampaignJobboard[] = [];
  private _isAvailable = true;
  private _alreadyInteracted = false;
  private _platformMatches: string[] = [];
  private _key: string;
  private _internal_id = 0;

  constructor(campaignIdResponse: number, candidateResponse: CampaignCandidateResponse) {
    this.campaignIdResponse = campaignIdResponse;
    this.candidateResponse = { ...candidateResponse };
    this.candidateJobBoardMatchesWithCandidateId = {
      [candidateResponse.candidate._source.source]: candidateResponse.candidate._id,
    };
    this._mainJobboard = candidateResponse.candidate._source.source;
    this._mainJobboardInfo = candidateResponse.candidate;
    this._key = uniqueId('cf-');
  }

  static clone(candidate: Candidate) {
    const newCandidate = new Candidate(candidate.campaignIdResponse, candidate.candidateResponse);
    newCandidate.candidateJobBoardMatchesWithCandidateId = candidate.candidateJobBoardMatchesWithCandidateId;
    newCandidate.candidateActivityLog = candidate.candidateActivityLog;
    newCandidate.candidateNotesResponse = candidate.candidateNotesResponse;
    newCandidate._mainJobboard = candidate._mainJobboard;
    newCandidate._mainJobboardInfo = candidate._mainJobboardInfo;
    newCandidate._removedJobboards = candidate._removedJobboards;
    newCandidate._isAvailable = candidate._isAvailable;
    newCandidate._alreadyInteracted = candidate._alreadyInteracted;
    newCandidate._key = candidate._key;
    newCandidate._platformMatches = candidate._platformMatches;
    newCandidate._internal_id = candidate._internal_id + 1;

    return newCandidate;
  }

  get internal_id() {
    return `${this.key}-${this._internal_id}`;
  }

  get key(): IMCandidate['key'] {
    return this._key;
  }

  get candidateId(): IMCandidate['candidateId'] {
    return this.candidateResponse.id;
  }

  get campaignId(): IMCandidate['campaignId'] {
    return this.campaignIdResponse;
  }

  get status(): IMCandidate['status'] {
    return this.candidateResponse.status ?? CANDIDATE_STATUS.NEW;
  }

  get es_person_id(): IMCandidate['es_person_id'] {
    return this.candidateResponse.candidate._id;
  }

  get es_person_id_without_platform(): IMCandidate['es_person_id_without_platform'] {
    return `${this._source.id}`;
  }

  get name(): IMCandidate['name'] {
    return this._source.name || this.headline;
  }

  set name(v: string) {
    this._source.name = v;
  }

  get firstName(): IMCandidate['firstName'] {
    return this.name.split(' ')[0];
  }

  get lastName(): IMCandidate['lastName'] {
    return this.name.split(' ')[this.name.split(' ').length - 1];
  }

  get headline(): IMCandidate['headline'] {
    return this._source.headline || 'N/A';
  }

  get location(): IMCandidate['location'] {
    const location = this._source.location;
    if (!location?.raw || location?.raw.includes(' null ')) return undefined;
    const lat = location.coordinates?.lat && parseFloat(location.coordinates.lat.toString());
    const lon = location.coordinates?.lon && parseFloat(location.coordinates.lon.toString());

    return {
      name: location.raw,
      latlon: lat && lon ? { lat, lon } : undefined,
    };
  }

  get not_obfuscated_phone(): IMCandidate['not_obfuscated_phone'] {
    const [phone] = this._source.contact_info?.phone ?? ([] as undefined[]);
    if (`${phone}`?.includes('*') && this.activeJobBoard === 'cvt') return;

    return stripNonValidPhoneCharacters(`${phone}`);
  }

  get not_obfuscated_email(): IMCandidate['not_obfuscated_email'] {
    const [email] = this._source.contact_info?.email ?? ([] as undefined[]);
    if (email?.includes('*') && this.activeJobBoard === 'cvt') return;

    return email;
  }

  get contact_info(): IMCandidate['contact_info'] {
    const [originalEmail] = this._source.contact_info?.email ?? ([] as undefined[]);
    const [originalPhone] = this._source.contact_info?.phone ?? ([] as undefined[]);
    const [platform] = this._source.contact_info?.[this.activeJobBoard] ?? ([] as undefined[]);

    const email = `${originalEmail}`?.includes('*') && this.activeJobBoard === 'cvt' ? undefined : originalEmail;
    const phone = `${originalPhone}`?.includes('*') && this.activeJobBoard === 'cvt' ? undefined : originalPhone;

    return {
      platform,
      email: email ? (this.isUnlocked ? email : candidatesUtils.obfuscateEmail(email)) : undefined,
      phone: phone ? (this.isUnlocked ? `${phone}` : candidatesUtils.obfuscatePhone(`${phone}`)) : undefined,
    };
  }

  get originalContactInfo(): IMCandidate['originalContactInfo'] {
    return this._source.contact_info;
  }

  get experiences(): IMCandidate['experiences'] {
    const sortedJobs = [...(this._source.experiences.jobs ?? [])].reverse();

    return sortedJobs;
  }

  get volunteeringExperiences(): IMCandidate['volunteeringExperiences'] {
    const volunteer = this._source.experiences?.volunteer ?? [];

    return candidatesService.getLastVolunteeringExperiences(volunteer);
  }

  get courses(): IMCandidate['courses'] {
    const courses = this._source.experiences?.courses ?? [];

    return candidatesService.getLastCourses(courses);
  }

  get certifications(): IMCandidate['certifications'] {
    const certifications = this._source.experiences?.certifications ?? [];

    return candidatesService.getLastCertifications(certifications);
  }

  get lastThreePositions(): IMCandidate['lastThreePositions'] {
    const jobs = this._source.experiences?.jobs ?? [];

    return candidatesService.getLastPositions(jobs, 3);
  }

  get education(): IMCandidate['education'] {
    const educations = this._source.experiences?.educations ?? [];

    return candidatesService.getLastEducations(educations).filter(Boolean);
  }

  get skills(): IMCandidate['skills'] {
    return this._source.experiences?.skills ?? [];
  }

  get lastEducation(): IMCandidate['lastEducation'] {
    const educations = this._source.experiences?.educations ?? [];
    const [lastEducation] = candidatesService.getLastEducations(educations, 1);

    return lastEducation;
  }

  get totalTimeEducations(): IMCandidate['totalTimeEducations'] {
    const { first, last, gap } = this._source.meta.experience.academic;

    const duration = sharedUtils.getTotalDuration(first, last, gap);

    return duration;
  }

  get totalTimeExperiences(): IMCandidate['totalTimeExperiences'] {
    const { first, last, gap } = this._source.meta.experience.professional;

    const duration = sharedUtils.getTotalDuration(first, last, gap);

    return duration;
  }

  get totalTimeVolunteeringExperiences(): IMCandidate['totalTimeVolunteeringExperiences'] {
    if (this._source.meta.experience?.volunteer) {
      const { first, last, gap } = this._source.meta.experience.volunteer;

      const duration = sharedUtils.getTotalDuration(first, last, gap);

      return duration;
    }

    return () => undefined;
  }

  get licenses(): IMCandidate['licenses'] {
    return this._source.licenses || [];
  }

  get languages(): IMCandidate['languages'] {
    const languages = this._source.experiences?.languages ?? [];
    if (languages.length === 0) return undefined;

    return languages.map((language) => ({
      name: language.name,
      proficiency: language.proficiency?.toLowerCase() !== 'unknown' ? language.proficiency : undefined,
    }));
  }

  get preferences(): IMCandidate['preferences'] {
    const preferences = this._source.preferences;
    if (!preferences) return [];

    return candidatesService.getPreferences(preferences);
  }

  get lastRefresh(): IMCandidate['lastRefresh'] {
    if (!this._source.last_insight_date) {
      return 'No info';
    }

    return new Date(this._source.last_insight_date);
  }

  get lastCrawled(): IMCandidate['lastCrawled'] {
    if (!this._source.crawlerMeta?.updatedAt) {
      return 'No info';
    }

    return new Date(this._source.crawlerMeta?.updatedAt);
  }

  get isUnlocked(): IMCandidate['isUnlocked'] {
    return this.candidateResponse.is_unlocked || false;
  }

  set isUnlocked(is_unlocked) {
    this.candidateResponse.is_unlocked = is_unlocked;
  }

  get mainJobboard(): IMCandidate['activeJobBoard'] {
    return this._mainJobboard;
  }

  get activeJobBoard(): IMCandidate['activeJobBoard'] {
    return this._source.source;
  }

  get jobBoards() {
    const availableJobBoards = Object.keys(this.candidateJobBoardMatchesWithCandidateId) as CampaignJobboard[];

    return availableJobBoards.map((jobBoard) => [jobBoard, jobBoard === this.activeJobBoard]) as Array<
      [CampaignJobboard, boolean]
    >;
  }

  get jobBoardMatchesWithCandidateId(): IMCandidate['jobBoardMatchesWithCandidateId'] {
    return this.candidateJobBoardMatchesWithCandidateId;
  }

  get reasons(): IMCandidate['reasons'] {
    return this.candidateResponse.reasons ?? [];
  }

  get isAvailable(): IMCandidate['isAvailable'] {
    return this._isAvailable;
  }

  set isAvailable(value: boolean) {
    this._isAvailable = value;
  }

  get alreadyInteracted(): IMCandidate['alreadyInteracted'] {
    return this._alreadyInteracted;
  }

  set alreadyInteracted(value: boolean) {
    this._alreadyInteracted = value;
  }

  get insight(): IMCandidate['insight'] {
    const insights = this._source.insights ?? [];

    const lastInsightIndex = insights.length - 1;
    const lastInsight = insights[lastInsightIndex];
    if (lastInsight && !lastInsight?.createdAt && this._source.last_insight_date) {
      lastInsight.createdAt = new Date(this._source.last_insight_date);
    }

    const insightChecker = new InsightChecker(insights);

    return {
      candidate: {
        new: insightChecker.check('candidate.added'),
        updated: insights.length > 0 && insightChecker.check('candidate.added', { checkDiff: true }),
      },
      education: {
        added: insightChecker.check('educations.added'),
        dateChanged:
          insightChecker.check('educations_dates_start.changed') ||
          insightChecker.check('educations_dates_end.changed'),
        obtainedDegreeChanged: insightChecker.check('educations_obtainedDegree.changed'),
      },
      job: {
        added: insightChecker.check('jobs.added'),
        roleChanged: insightChecker.check('jobs_role.changed'),
        descriptionChanged: insightChecker.check('jobs_description.changed'),
        dateChanged: insightChecker.check('jobs_dates_start.changed') || insightChecker.check('jobs_dates_end.changed'),
      },
      skill: {
        added: insightChecker.check('skills.added'),
      },
      preferred: {
        employmentTypeChanged: insightChecker.check('preferences_employmentType.changed'),
        workHoursChanged: insightChecker.check('preferences_workHours.changed'),
      },
      work: {
        availabilityChanged: insightChecker.check('preferences_availability.changed'),
      },
      location: {
        changed: insightChecker.check('location_raw.changed'),
      },
      phone: {
        added: insightChecker.check('contact_info_phone.added'),
        changed: insightChecker.check('contact_info_phone.changed'),
      },
      email: {
        added: insightChecker.check('contact_info_email.added'),
        changed: insightChecker.check('contact_info_email.changed'),
      },
    };
  }

  get about(): IMCandidate['about'] {
    const about = this._source.description;
    if (!about) return undefined;

    return about;
  }

  get activityLog(): IMCandidate['activityLog'] {
    return this.candidateActivityLog;
  }

  get quickActionHistory(): IMCandidate['quickActionHistory'] {
    const quickActionHistory: Array<{ variant: HistoryIconVariant; title?: string; created_at?: Date }> = [];

    if (this.candidateResponse.candidate.actions && this.candidateResponse.candidate.actions.length > 0) {
      this.candidateResponse.candidate.actions.forEach((action) => {
        if (
          action.action === CANDIDATE_ACTIVITY_LOG_STATUS.CAMPAIGN_CANDIDATE_INVISIBLE ||
          action.action === CANDIDATE_ACTIVITY_LOG_STATUS.CAMPAIGN_CANDIDATE_SENT_TO_ATS ||
          action.action === CANDIDATE_ACTIVITY_LOG_STATUS.CAMPAIGN_CANDIDATE_UNLOCKED
        ) {
          return;
        }

        quickActionHistory.push({
          created_at: new Date(action.created_at),
          variant: action.action,
        });
      });
    }

    if (this.candidateResponse.candidate.last_matched_date) {
      quickActionHistory.push({
        created_at: new Date(this.candidateResponse.candidate.last_matched_date),
        variant: CANDIDATE_ACTIVITY_LOG_STATUS.CAMPAIGN_CANDIDATE_MATCHED,
      });
    }

    return quickActionHistory;
  }

  /**
   * @notice Do not use this property directly, use @link `src/shared/contexts/UserContext/CandidateContext.useIsCvAvailable.ts` instead
   */
  get __cvAvailable(): IMCandidate['__cvAvailable'] {
    if (!this.isUnlocked && this.activeJobBoard === CampaignJobboard.Monsterboard) return false;

    if (this.status === CANDIDATE_STATUS.HIDDEN) return false;
    else if (this.status === CANDIDATE_STATUS.MATCHED) return true;

    return !!this.cvFileId;
  }

  get cvFileId(): IMCandidate['cvFileId'] {
    return this._source.cvFileId;
  }

  get campaign(): IMCandidate['campaign'] {
    return this.candidateResponse.campaign;
  }

  get lastInteraction(): IMCandidate['lastInteraction'] {
    return new Date(this.candidateResponse.updated_at);
  }

  get hasNotes(): IMCandidate['hasNotes'] {
    return Boolean(this.candidateResponse.candidate.has_note || this.notes.length > 0);
  }

  get notes(): IMCandidate['notes'] {
    return (this.candidateNotesResponse ?? []).sort((a, b) => b.id - a.id);
  }

  get platformMatches(): IMCandidate['platformMatches'] {
    return this._platformMatches;
  }

  get lastActionMadeBy(): IMCandidate['lastActionMadeByUser'] {
    return this.candidateResponse.candidate.last_action_made_by_user;
  }

  getCsvData: IMCandidate['getCsvData'] = ({ currentUserId }) => {
    return {
      email: this.contact_info?.email,
      phone: this.contact_info?.phone,
      sourceLink: this.contact_info?.platform,
      profileLink:
        `${window.location.origin}` +
        routes.sharedCandidatePage({
          campaignId: this.campaignId,
          candidateId: this.es_person_id,
          userId: currentUserId,
        }),
      name: this.name,
      source: this._source.source,
      sourceMeta: this._source.sourceMeta,
    };
  };

  removeCandidateMatch: IMCandidate['removeCandidateMatch'] = (jobboardId) => {
    if (!this.candidateJobBoardMatchesWithCandidateId[jobboardId]) {
      throw new TypeError('[Candidate.removeCandidateMatch] - Provided jobboard not exists');
    }

    delete this.candidateJobBoardMatchesWithCandidateId[jobboardId];
    this._removedJobboards.push(jobboardId);

    return this;
  };

  bindCandidateMatches: IMCandidate['bindCandidateMatches'] = (platformMatches = []) => {
    const jobBoardMatchesWithJobBoardId = candidatesUtils.extractCandidateIdWithJobBoardFromMatches([
      this.es_person_id,
      ...platformMatches,
    ]);

    this._removedJobboards.forEach((jobboardId) => {
      delete jobBoardMatchesWithJobBoardId[jobboardId];
    });

    this._platformMatches = platformMatches;
    this.candidateJobBoardMatchesWithCandidateId = jobBoardMatchesWithJobBoardId;

    return this;
  };

  bindCandidateActivityLog: IMCandidate['bindCandidateActivityLog'] = (activityLog) => {
    this.candidateActivityLog = activityLog;

    return this;
  };

  loadMainJobboard: IMCandidate['loadMainJobboard'] = () => {
    this.candidateResponse.candidate = this._mainJobboardInfo;

    return this;
  };

  setCandidateInfo: IMCandidate['setCandidateInfo'] = (candidate, { preserveQuickHistory = false } = {}) => {
    const last_matched_date = this.candidateResponse.candidate.last_matched_date;
    const actions = this.candidateResponse.candidate.actions;

    this.candidateResponse.candidate = candidate;

    if (preserveQuickHistory) {
      this.candidateResponse.candidate.last_matched_date = last_matched_date;
      this.candidateResponse.candidate.actions = actions;
    }

    return this;
  };

  mergeSource = (source: Partial<Source>) => {
    return this.mergeLocally({
      candidate: { ...this.candidateResponse.candidate, _source: { ...this._source, ...source } },
    });
  };

  mergeCandidateResponse = (candidateResponse: Partial<CandidateResponse>) => {
    return this.mergeLocally({
      candidate: { ...this.candidateResponse.candidate, ...candidateResponse },
    });
  };

  mergeLocally = (campaignCandidateResponse: Partial<CampaignCandidateResponse>) => {
    this.candidateResponse = {
      ...this.candidateResponse,
      ...campaignCandidateResponse,
      candidate: { ...this.candidateResponse.candidate, ...campaignCandidateResponse.candidate },
    };

    return this;
  };

  mergeNotesResponse = (notesResponse: CampaignCandidateSaveNotesResponse[] | undefined) => {
    if (!notesResponse) {
      this.candidateNotesResponse = undefined;
    } else {
      this.candidateNotesResponse = [
        ...(this.candidateNotesResponse ?? []).filter(({ id: _id }) => !notesResponse.some(({ id }) => id === _id)),
        ...notesResponse,
      ];
    }

    return this;
  };

  private get _source() {
    return this.candidateResponse.candidate._source;
  }
}

export interface IMCandidate {
  key: string;
  candidateId: number;
  campaignId: number;
  status: CandidateStatus;
  es_person_id: string;
  es_person_id_without_platform: string;
  name: string;
  firstName: string;
  lastName: string;
  headline: string;
  location: { name: string; latlon: LatLon | undefined } | undefined;
  not_obfuscated_phone: string | undefined;
  not_obfuscated_email: string | undefined;
  contact_info: { email: string | undefined; phone: string | undefined; platform: string | undefined };
  originalContactInfo: Source['contact_info'];
  experiences: Job[];
  volunteeringExperiences: Array<{
    name: string;
    duration: (d: Dictionary) => string;
    startYear: number;
    endYear: (d: Dictionary) => number | undefined | string;
    description?: string;
    fullDuration: (d: Dictionary) => string;
  }>;
  lastThreePositions: Array<{
    name: string;
    duration: (d: Dictionary) => string;
    startYear: number;
    endYear: (d: Dictionary) => number | undefined | string;
    companyName?: string;
    positionName: string;
  }>;
  education: Array<{
    name: string;
    description?: string;
    duration: (d: Dictionary) => string;
    startYear: number;
    endYear: number | undefined;
    fullDuration: (d: Dictionary) => string | null;
  }>;
  courses?: Array<{
    name: string;
  }>;
  certifications?: Array<{
    name: string;
    startYear?: number;
  }>;
  skills: Array<{ name: string }>;
  lastEducation:
    | { name: string; duration: (d: Dictionary) => string; startYear: number; endYear: number | undefined }
    | undefined;
  licenses: License[];
  languages: Array<{ name: string; proficiency: string | undefined }> | undefined;
  preferences: Array<{ title: keyof Pick<Dictionary, ShowingPreferences>; description: string }>;
  lastRefresh: Date | string;
  lastCrawled: Date | string;
  isUnlocked: boolean;
  isAvailable: boolean;
  alreadyInteracted: boolean;
  mainJobboard: CampaignJobboard;
  activeJobBoard: CampaignJobboard;
  jobBoards: Array<[CampaignJobboard, boolean]>;
  jobBoardMatchesWithCandidateId: Partial<Record<CampaignJobboard, string>>;
  reasons: string[];
  insight: CandidateInsight;
  about: string | undefined;
  activityLog: CandidateActivityLog[];
  quickActionHistory: Array<{ variant: HistoryIconVariant; title?: string; created_at?: Date }>;
  __cvAvailable: boolean;
  cvFileId: string | undefined;
  campaign: Omit<CampaignResponse, 'created_at' | 'current_filter' | 'rated_candidates' | 'updated_at'> | undefined;
  lastInteraction: Date;
  hasNotes: boolean;
  notes: CampaignCandidateSaveNotesResponse[];
  platformMatches: string[];
  loadMainJobboard: () => Candidate;
  getCsvData: (params: { currentUserId: number }) => {
    name: string;
    email?: string;
    phone?: string;
    source: CampaignJobboard;
    sourceLink?: string;
    profileLink: string;
    sourceMeta: SourceMeta | undefined;
  };
  removeCandidateMatch: (jobboardId: CampaignJobboard) => Candidate;
  bindCandidateMatches: (platformMatches: string[] | undefined) => Candidate;
  bindCandidateActivityLog: (activityLog: CandidateActivityLog[]) => Candidate;
  totalTimeExperiences: (d: Dictionary) => string;
  totalTimeVolunteeringExperiences: (d: Dictionary) => string | undefined;
  totalTimeEducations: (d: Dictionary) => string;
  setCandidateInfo: (
    candidate: CampaignCandidateResponse['candidate'],
    config?: { preserveQuickHistory?: boolean },
  ) => Candidate;
  lastActionMadeByUser?: CandidateResponse['last_action_made_by_user'];
}

export type CandidateInsight = {
  candidate: {
    new: boolean;
    updated: boolean;
  };
  education: {
    added: boolean;
    dateChanged: boolean;
    obtainedDegreeChanged: boolean;
  };
  job: {
    added: boolean;
    roleChanged: boolean;
    descriptionChanged: boolean;
    dateChanged: boolean;
  };
  skill: {
    added: boolean;
  };
  preferred: {
    employmentTypeChanged: boolean;
    workHoursChanged: boolean;
  };
  work: {
    availabilityChanged: boolean;
  };
  location: {
    changed: boolean;
  };
  phone: {
    added: boolean;
    changed: boolean;
  };
  email: {
    added: boolean;
    changed: boolean;
  };
};

export type HistoryIconVariant = Extract<
  CandidateActivityLogStatus,
  | 'CAMPAIGN_CANDIDATE_LABEL_CHANGED'
  | 'CAMPAIGN_CANDIDATE_SHORTLISTED'
  | 'CAMPAIGN_CANDIDATE_MATCHED'
  | 'CAMPAIGN_CANDIDATE_CONTACTED'
  | 'CAMPAIGN_CANDIDATE_HIDDEN'
>;
