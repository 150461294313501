import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { NotifyCandidateData, NotifyCandidateMethod } from 'clients/CampaignsClient/CampaignsClient.types';
import { userClient } from 'clients/UserClient/UserClient';
import { isAxiosError } from 'helpers/clientHelpers';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useContext } from 'react';
import { CANDIDATE_STATUS } from 'services/candidates';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { BadRequestException } from 'shared/exceptions/BadRequestException';
import { IndeedNoCreditsAvailableException } from 'shared/exceptions/IndeedNoCreditsAvailableException';
import { useBrowserExtension } from 'shared/hooks';
import { sharedUtils } from 'utils';
import { MailInputValue } from '../types';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useContact = (params: WrappedHookParams) => {
  const { dictionary } = useContext(LocalizationContext);
  const { currentUser, mailSignOff } = useContext(UserContext);
  const { sendLinkedinMessage, sendIndeedMessage, sendLinkedinProMessage, indeedCreditBalance } = useBrowserExtension();

  return useCandidateAction<ContactHookCallbackParams>(
    async ({ candidate, data, sendMethod, extras }) => {
      const requests: Promise<any>[] = [];

      if (sendMethod === 'linkedin') {
        requests.push(
          sendLinkedinMessage({
            ...(data.body !== undefined && {
              customMessage: sharedUtils.htmlToPlainText(data.body),
            }),
            inviteeProfileUrn: `urn:li:fsd_profile:${candidate.es_person_id_without_platform}`,
          }),
        );
      } else if (sendMethod === 'indeed' && currentUser) {
        if (indeedCreditBalance === 0) {
          throw new IndeedNoCreditsAvailableException(dictionary.noCreditsAvailable);
        }

        if (!extras?.indeedProjectId) {
          throw TypeError('indedeProjectId must be provided');
        }

        const tenant = await userClient.getTenantCurrentUser();

        requests.push(
          sendIndeedMessage({
            accountKeys: [candidate.es_person_id_without_platform],
            senderName: mailSignOff.fullName as string,
            senderCompany: `${tenant.data.name}`,
            message: data.body as string,
            jobTitle: data.subject as string,
            description: '...',
            projectKey: extras.indeedProjectId,
          }),
        );
      } else if (sendMethod === 'linkedin_inmail') {
        requests.push(
          sendLinkedinProMessage({
            candidate_id: candidate.es_person_id_without_platform,
            subject: data.subject as string,
            body: data.body as string,
            signature: mailSignOff.fullName as string,
          }),
        );
      }

      function sanitizeText(text: string = '') {
        let value = text;
        const allowedCharacters = /^[a-zA-Z0-9\s&*():;'"!@\?\{\}]*$/;

        if (!allowedCharacters.test(value)) {
          const cleanedValue = value.replace(/[^a-zA-Z0-9\s&*():;'"!@\?\{\}]/g, '');
          value = cleanedValue;
        }

        return value;
      }

      const notifyCandidateData: NotifyCandidateData = {
        message: data.body,
        sign_off:
          sendMethod !== 'werknl'
            ? data.signOff || [mailSignOff?.fullName, mailSignOff?.phoneNumber].filter(Boolean).join('\n')
            : mailSignOff?.fullName,
        subject: sendMethod === 'werknl' ? sanitizeText(data.subject) : data.subject,
        email: data.email,
        phone_number: sendMethod === 'werknl' ? data.phoneNumber?.replace(/\D/g, '') : data.phoneNumber,
        website: data.website,
        status: 'success',
      };

      await Promise.all(requests);

      await campaignsClient.notifyCandidate({
        campaign_id: candidate.campaignId,
        candidate_id: candidate.es_person_id,
        send_method: sendMethod,
        data: notifyCandidateData,
      });
    },
    {
      onSuccess: ({ fcParams: { candidate, sendMethod }, gtm, setOutreachModalConfigLabels }) => {
        gtm.contactCandidate(candidate.campaignId, candidate.es_person_id);
        gtm.contactSentMessage(candidate.campaignId, candidate.es_person_id, sendMethod);

        return async ({ candidateOrchestrator: co, pageContext }) => {
          if (pageContext === PageContext.Campaign) {
            if (candidate.status === CANDIDATE_STATUS.NEW) {
              co.campaign.decreaseStatOf.currentJobBoard();
            }

            setOutreachModalConfigLabels(co.campaign.update.labels.add('contacted'));

            if ([CANDIDATE_STATUS.MATCHED, CANDIDATE_STATUS.CONTACTED].every((s) => s !== candidate.status)) {
              co.campaign.update.locally({ status: CANDIDATE_STATUS.CONTACTED });
              co.campaign.remove.fromCurrentList();
              co.campaign.reset.contactList();
              co.campaign.decreaseStatOf.currentList();
              co.campaign.increaseStatOf.contactList();
            }
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            setOutreachModalConfigLabels(co.share.update.labels.add('contacted'));

            if (candidate.status !== CANDIDATE_STATUS.MATCHED) {
              co.share.moveCandidateTo.contactList();
            }
          }
        };
      },
      onError: ({ fcParams: { candidate, sendMethod }, gtm }) => {
        gtm.contactCandidateFailed(candidate.campaignId, candidate.es_person_id, sendMethod);

        return ({ data }) => {
          if (isAxiosError(data) && data.response?.status === 400 && sendMethod === 'email') {
            throw new BadRequestException();
          }

          throw data;
        };
      },
    },
  )(params);
};

export type ContactHookCallbackParams = {
  data: MailInputValue;
  sendMethod: NotifyCandidateMethod;
  extras?: {
    indeedProjectId?: string;
  };
};
